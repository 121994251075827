<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.categoryName}}</span><span>待上门收运</span></p>
    <p>订单编号: <span>{{item.orderNo}}</span></p>
    <p>产废企业: <span>{{item.companyName}}</span></p>
    <p>姓名: <span style="margin-right: 10px">{{item.trueName}}</span>  电话: <span>{{item.userPhone}}</span></p>
    <p>接单时间: <span>{{conversionTime(item.takingTime)}}</span></p>
    <p v-if="item.appointmentBeginTime">预约时间: <span>{{item.appointmentBeginTime}}-{{item.appointmentEndTime.substring(11)}}</span></p>
    <div class="btns">
      <van-button plain type="default" size="small" @click="confirmClick(item.id)">确认订单</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserOrderDsm',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
    }
  },
  methods: {
    confirmClick(id) { // 确认订单
     this.$router.push({path: 'Orderdetails', query: {id: id}})
    },
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
